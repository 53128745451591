// Prism

/* Code blocks */

pre[class*="language-"] {
  padding: 1em;
  overflow: auto;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 0.5rem !important;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: var(--#{$prefix}gray-900);
}

div.code-toolbar {
  position: relative;

  > .toolbar {
    position: absolute;
    top: 0.3em;
    right: 0.2em;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  &:hover > .toolbar,
  &:focus-within > .toolbar {
    opacity: 1;
  }

  > .toolbar > .toolbar-item {
    display: inline-block;

    > {
      a {
        cursor: pointer;
      }

      button {
        background: none;
        border: 0;
        color: var(--#{$prefix}dark);
        font: inherit;
        line-height: normal;
        overflow: visible;
        padding: 0;
      }

      a,
      button,
      span {
        color: var(--#{$prefix}dark);
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        background: var(--#{$prefix}white);
        font-weight: 600;
        border-radius: 0.25rem;
      }

      a {
        &:hover,
        &:focus {
          color: var(--#{$prefix}dark);
          text-decoration: none;
        }
      }

      button {
        &:hover,
        &:focus {
          color: var(--#{$prefix}dark);
          text-decoration: none;
        }
      }

      span {
        &:hover,
        &:focus {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}
