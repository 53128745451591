// Plyr

@import "../../../../node_modules/plyr/src/sass/plyr.scss";

.plyr__control--overlaid {
  font-size: 24px;
  line-height: 32px;
  position: relative;

  position: absolute;
  &:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;

    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
    top: -12px;
    left: -12px;
  }
}

.plyr__poster {
  background-size: cover;
}

.plyr__video-wrapper {
  border: 2px solid $plyr-color-gray-200;
  border-radius: 0.75rem;
}
.plyr--video .plyr__controls {
  background-color: $plyr-color-gray-800;
  opacity: 0.75;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  padding-top: 0.75rem;
}
