// Avatar

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

// Avatar Size

.avatar-xs {
  width: $avatar-size-xs;
  height: $avatar-size-xs;
}

.avatar-sm {
  width: $avatar-size-sm;
  height: $avatar-size-sm;
}

.avatar-md {
  width: $avatar-size-md;
  height: $avatar-size-md;
}

.avatar-lg {
  width: $avatar-size-lg;
  height: $avatar-size-lg;
}

.avatar-xl {
  width: $avatar-size-xl;
  height: $avatar-size-xl;
}

.avatar-xxl {
  width: $avatar-size-xxl;
  height: $avatar-size-xxl;
}
