article a {
  font-size: 23px;
}

article h1 {
  font-size: calc(1rem * 3.125);
}

article h2 {
  font-size: calc(1rem * 2.3125);
}

article h3 {
  font-size: calc(1rem * 1.75);
}

article h4 {
  font-size: calc(1rem * 0.875);
}

article img {
  width: 100%;
}

.wide-background {
  background-size:cover;
  background-repeat:no-repeat; 
  background-position: center center;
  background-image:url('/assets/images/generic/arpedon-abstract-blue-background-3.jpg');
}

.wide-background {
  background-size:cover;
  background-repeat:no-repeat; 
  background-position: center center;
  background-image:url('/assets/images/generic/arpedon-abstract-blue-background-3.jpg');
}

// Add navbar half underline in the transparent view
@include media-breakpoint-up(lg) {
  .navbar-dark {
    border-bottom: 1px solid;
    border-image: linear-gradient(to left, $white 65%, transparent 40%) 100% 1;
  }

  .wide-background {
    height: 54vh !important;
    background-size: cover !important;
  }
}

.navbar-toggler .bi.bi-arrow-down-left::before {
  vertical-align: 0 !important;
}

.navbar-stick:not(.navbar-dark) {
  box-shadow: none !important;
  background: $light !important;
}

.btn.bm.collapsed {
  position: absolute;
  right: 0;
  color: $secondary;
  z-index: 100;
}

.btn.bm {
  position: absolute;
  color: $secondary;
  right: 0;
  z-index: 100;
}

.pb-p {
  margin-top: 20px;
}

.pb-a {
  color: #111;
  font-size: 23px;
  line-height: 28px;
}

.pb-w {
  height: 60px;
  margin-bottom: 20px;
}

.btn {
  border: none !important;
}

.col-md-4.th-4.press .col-12 {
  padding-left: 0;
}

.btn.bm.collapsed:active {
  border: none;
}

.text-more {
  text-align: right;
  margin-top: 30px;
}

.m-11 {
  margin-bottom: 340px;
}

.espa-badge {
  position: absolute;
  bottom: 80px;
  left: 80px;
}

.info-head {
  height: 90px;
}

.list-inline-item:not(:last-child) {
  margin-right: 20px !important;
}

.dropdown-menu .dropdown-item:hover {
  color: $secondary;
  text-decoration: underline;
  text-underline-offset: 10px;
}


.navbar-light .navbar-nav .nav-item .nav-link:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
}

.terms .float-right {
  margin-top: 5px;
}

.form-row {
  margin-bottom: 30px;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 550px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 0;
  color: $black;
  text-align: center;
  background: none;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
  height: 50px;
  text-transform: uppercase;
}

.carousel-control-next {
  right: 10%;
}

.carousel-control-prev {
  left: 60%;
}

#ft-links .fw-light p {
  color: $white;
  margin-bottom: 15px;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: $black;
  opacity: 0.9;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

#filterOptions {
  padding-left: 15px;
}

#filterOptions a {
  color: $primary;
  font-size: 16px;
}

#filterOptions .active a {
  color: $white;
}

#filterOptions li {
  display: inline;
  padding: 15px 47px;
  margin-right: 10px;
  border: 2px solid $primary;
  border-radius: 5px;
  color: $primary;
}

#filterOptions .active {
  background: $secondary;
  border: 2px solid $secondary;
  color: $white;
}

#FilterHolder {
  margin-top: 340px;
}

@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1400px !important;
  }

  .r-d {
    padding: 0 !important;
  }
}

.large-row {
  padding-top: 340px;
}

.xs-pad-row {
  padding-top: 120px;
  padding-bottom: 120px;
}

.sm-pad-row {
  padding-top: 160px;
  padding-bottom: 160px;
}

.xl-pad-row {
  padding-top: 220px;
  padding-bottom: 220px;
}

.main-page {
  margin-top: 120px;
  margin-bottom: 60px;
}

.m1 {
  margin-top: 340px;
}

.m2 {
  margin-top: 270px;
}

.m3 {
  margin-top: 180px;
}

/*titles and basics*/
.page-title {
  font-size: 90px;
  font-weight: 600;
  line-height: 80px;
  margin-bottom: 60px;
}

.centered-title {
  font-size: 90px;
  font-weight: 600;
  line-height: 109px;
  margin-bottom: 20px;
  text-align: center;
}

.home-hero {
  height: 100vh;
  justify-content: start;
  background-image: url('img/homepage/1-arpedon-homepage-hero.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.slide-title {
  max-width: 1180px;
  color: $white;
  text-align: left;
  font-size: 128px;
  font-weight: 700;
  line-height: 136px;
  letter-spacing: 0px;
  color: white;
  position: relative;
}

.slide-subtitle {
  color: white;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  position: relative;
}

.link-underline {
  color: $secondary;
  text-decoration: none;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  position: relative;
  /* top:426px;*/
  max-width: 400px;
  display: inline-block;
}

.link-underline:hover {
  text-decoration: none;
  color: $secondary;
}

.link-underline:after {
  padding-top: 9px;
  display: block;
  content: '';
  width: inherit;
  border-bottom: solid 2px $secondary;
  transform: scaleX(0);
  transition: transform 400ms ease-in-out;
  transform-origin: left;
}

.link-underline:hover:after {
  transform: scaleX(1);
}

.bi.bi-arrow-up-right {
  height: 16px;
  display: inline-block;
  overflow: hidden;
  width: 23px;
}

.link-underline--white {
  color: $white !important;

  &:hover {
    color: $white !important;
  }

  &:after {
    border-bottom: solid 2px $white !important;
  }
}

@include media-breakpoint-up(lg) {
  .link-underline:after {
    transform: scaleX(0);
  }
}

.ps-link {
  color: $white;
  text-decoration: underline;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.header-white-64 {
  font-size: 64px;
  line-height: 77px;
  font-weight: 600;
  color: $white;
  margin-bottom: 30px;
}

.header-white-90 {
  font-size: 90px;
  line-height: 109px;
  font-weight: 600;
  color: $white;
}

.numb-list {
  align-items: center;
  border-bottom: 2px solid $primary;
  padding: 40px 0;
}

.numb-list:first-child {
  align-items: center;
  border-bottom: 2px solid $primary;
  padding: 0 0 40px 0;
}

.stroke-numb {
  color: $secondary;
  -webkit-text-fill-color: $light;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: $secondary;
  font-size: 160px;
  font-weight: bold;
  line-height: 160px;
  font-family: 'Roboto Mono' !important;
}

.large-numb {
  color: $secondary;
  -webkit-text-fill-color: $light;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: $secondary;
  font-weight: bold;
  font-family: 'Roboto Mono' !important;
  font-size: 272px;
  line-height: 359px;
}

.text-description {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #111;
  margin-top: 20px;
  margin-bottom: 60px;
  max-width: 426px;
}

.centered-text {
  text-align: center;
  padding: 0 300px;
}

.info-card {
  background: white;
  padding: 70px 56px;
}

.medium-32 {
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
  color: #111111;
  border-bottom: 20px;
  max-width: 720px;
}

.info-card-icon {
  height: 126px;
  width: auto;
  margin: 139px auto;
  display: flex;
}

.iso-logo {
  margin: 0 auto 60px auto;
  display: flex;
}

.medium-white-23 {
  color: white;
  text-align: center;
  font-size: 23px;
  line-height: 28px;
  font-weight: 500;
  max-width: 300px;
}

.medium-white-23:hover {
  color: white;
}

.medium-white-23-subtext {
  color: white;
  text-align: center;
  font-size: 23px;
  line-height: 28px;
  font-weight: 500;
  padding: 60px 200px;
}

.maintenance-categories {
  -webkit-box-shadow: inset 0px 0px 0px 2px $primary;
  -moz-box-shadow: inset 0px 0px 0px 2px $primary;
  box-shadow: inset 0px 0px 0px 2px $primary;
  border-radius: 5px;
  padding: 75px;
  height: 670px;
}

.bordered-col {
  border-left: 2px solid white;
}

/* changed for mechbase */
.other-products {
  background: white;
  padding: 75px;
  height: 804px;
  border-radius: 5px
}

.box {
  margin-top: 217px;
  padding: 60px 50px;
  border-radius: 5px;
}

.predictive-box {
  background: white;
  padding: 120px;
  border-radius: 5px;
}

.p-link {
  color: $secondary;
  text-decoration: underline;
}

.p-text {
  height: 150px;
  margin-bottom: 20px;
}

.down-icon {
  margin-top: 10px;
  margin-left: 10px;
}

.predictive-box img {
  text-align: center !important;
  padding: 0 20px;
}

.expertise {
  padding: 0 202px;
}

.terms {
  padding: 10px 0;
  border-bottom: 2px solid $primary;
  margin: 0 0px 20px 162px
}

.terms p {
  font-size: 23px;
  line-height: 28px;
  font-weight: 500;
  color: #111;
}

.terms .bi.bi-download {
  justify-content: end;
  display: flex;
  color: $secondary;
}

.contact-info-block {
  margin-top: 160px;
  max-width: 279px;
}

.contact-info-block a {
  color: #111;
  text-decoration: none;
}

.contact-info-block a:hover {
  color: #111;
  text-decoration: none;
}

.maintnode-hero {
  top: 90px;
  position: relative;
  padding-bottom: 278px;
}

.fire-fighting-systems {
  margin-bottom: 60px;
}

.page-error {
  margin-top: 160px;
}

.error-notice {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 140px;
}

.action-link {
  padding-top: 60px;
  /* padding-bottom: 160px; */
}

.call-link {
  padding-bottom: 160px;
}

.subscribe-link {
  padding-top: 60px;
}

/*accordion with image*/
.column-left {
  padding-right: 20px;
  justify-content: center;
  display: grid;
}

/*characteristics*/
.row.characteristics {
  line-height: 20px;
  padding: 10px 0 0 0;
  border-bottom: 2px solid $primary;
}

/*images*/
.platform {
  margin-top: 75px;
  margin-bottom: -75px;
}

.image-container {
  height: 320px;
  object-fit: contain;
}

@media only screen and (max-width:1440px) {
  .platform {
    margin-top: 25px;
    margin-bottom: -25px;
  }
}

/*logo-slider*/
.logo-slider {
  overflow: hidden;
  padding: 30px 0 0 0;
  white-space: nowrap;
  position: relative;
}

.logo-slider:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 45s slide infinite linear;
}

.logos-slide img {
  width: 140px;
  height: auto;
  margin: 0 75px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

/*swiper*/
.col-swiper {
  padding-right: 162px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width:1440px) {
  .page-title {
    font-size: 81px;
  }

  .page-title-center {
    font-size: 81px;
  }


  .header-white-64 {
    font-size: 58px;
  }

  .header-white-90 {
    font-size: 81px;
  }

  .accordion-header {
    font-size: 41px;
  }

  .large-header {
    font-size: 58px;
  }

  .link-underline {
    font-size: 21px;
  }

  .faq-accordion-item .faq-header .btn-header-link {
    font-size: 21px;
  }

  .terms p {
    font-size: 21px;
  }

  .expertise {
    padding: 0;
  }

  .medium-32 {
    font-size: 29px;
  }

  .stroke-numb {
    font-size: 144px;
  }
}

@media only screen and (max-width:1300px) {
  p {
    font-size: 16px;
  }

  .page-title {
    font-size: 72px;
    line-height: 1.2;
  }

  .centered-title {
    font-size: 72px;
    line-height: 1.2;
  }

  .header-white-64 {
    font-size: 51px;
    line-height: 1.2;
  }

  .header-white-90 {
    font-size: 72px;
  }

  .accordion-header {
    font-size: 36px;
    line-height: 1.2;
  }

  .link-underline {
    font-size: 18px;
    line-height: 1.2;
  }

  .faq-accordion-item .faq-header .btn-header-link {
    font-size: 18px;
    line-height: 1.2;
  }

  .terms p {
    font-size: 21px;
    line-height: 1.2;
  }

  .medium-white-23 {
    font-size: 21px;
    line-height: 1.2;
  }

  .medium-white-23-subtext {
    font-size: 21px;
    line-height: 1.2;
  }

  .medium-32 {
    font-size: 26px;
    line-height: 1.2;
  }

  .stroke-numb {
    font-size: 126px;
    /* line-height: 1.2;*/
  }

  .n-s {
    height: 25vh;
  }

  .maintnode-hero {
    width: 70%;
  }

  .sm-image {
    width: 70%;
  }

  .maintenance-categories {
    padding: 60px 30px;
  }

  .other-products {
    padding: 60px 30px;
  }
}

@media only screen and (max-width:1040px) {
  .offcanvas.offcanvas-start.offcanvas-nav.show {
    background: $light !important;
    overflow: hidden !important;
    border-color: $light !important;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    border: 0;
  }

  .navbar-light .navbar-nav .nav-item .nav-link:hover {
    text-decoration: none;
  }

  .nav-link.dropdown-toggle.show {
    color: $primary !important;
    font-weight: bold !important;
    text-decoration: none !important;
  }

  .page-title {
    text-align: center;
    width: auto;
    width: 100%;
  }

  .text-description {
    max-width: 100%;
  }

  .other-products {
    margin-bottom: 20px;
  }

  .large-numb {
    font-size: 180px;
    line-height: 189px;
  }

  .faq-accordion-item {
    max-width: 564px;
  }

  .terms p {
    font-size: 18px;
    line-height: 21px;
  }

  .medium-white-23 {
    font-size: 18px;
    line-height: 21px;
  }

  .medium-white-23-subtext {
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    padding: 20px 0;
  }

  .centered-text {
    padding: 0 50px;
  }

  .maintenance-categories {
    margin-bottom: 20px;
  }

  .box-column {
    max-width: 100%;
  }

  .contact-info-block {
    margin-top: 80px;
    max-width: 100%;
  }

  .error-notice {
    margin-bottom: 160px;
  }

  .action-link {
    display: flex;
    justify-content: center;
  }

  .action-link {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .wide-background {
    height: 40vh !important;
    background-size: cover !important;
  }

  .terms {
    margin-left: 0;
  }

  .sm-image {
    padding-top: 80px !important;
    width: 57%;
  }

  .maintnode-hero {
    width: 57%;
  }

  .predictive-box img {
    text-align: center !important;
    padding: 0 !important;
    margin-bottom: 40px !important;
  }

  .bordered-col {
    border-left: 0 solid white;
  }

  .em-col {
    margin-left: 33%;
    padding-left: 15px;
    padding-right: 265px;
  }

  .container.text-center.res-text-left {
    text-align: left !important;
  }

  .container.text-center.res-text-left .medium-white-23-subtext {
    padding: 0 !important;
    text-align: left;
  }

  .pb-a {
    font-size: 18px;
  }

  #FilterHolder {
    margin-top: 240px;
  }

  #filterOptions a {
    font-size: 11px;
    font-weight: bold;
  }

  #filterOptions li {
    display: inline;
    padding: 0;
    margin-right: 30px;
    border: 0;
    border-radius: 0;
    color: $primary;
    font-size: 11px;
    font-weight: bold;
  }

  #filterOptions .active {
    background: transparent;
    border: 0;
    color: $secondary !important;
    text-decoration: underline;
  }

  #filterOptions .active a {
    color: $secondary !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: 17px;
    top: 306px;
    justify-content: end;
    width: 70%;
    right: 0 !important;
    font-size: 14px;
  }

  .carousel-control-prev {
    left: 0;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 14px;
    height: 14px;
  }

  .carousel-control-prev-icon {
    margin-right: 5px;
  }

  .carousel-control-next-icon {
    margin-left: 5px;
  }

  .col-md-4.th-4.press .col-12 {
    padding-left: 0;
  }

  #holder .col-12 {
    padding-left: 0 !important;
  }

  .pb-w {
    min-height: 63px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width:767px) {
  .top-27 {
    padding-top: 16vh;
  }

  .down-icon {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .col-img {
    display: none;
  }

  .row {
    --bs-gutter-x: 0rem !important;
  }

  .text-more {
    text-align: left;
    margin-top: 30px;
  }

  .espa-badge {
    padding-top: 12vh;
    left: auto;
    bottom: 15px;
  }

  .call-link {
    text-align: center;
  }

  .page-title {
    font-size: 38px;
    line-height: 49px;
    text-align: center;
    width: auto;
    width: 100%;
  }

  .ac-img {
    display: none;
  }

  .centered-title {
    font-size: 38px;
    line-height: 44px;
  }

  .accordion-header {
    font-size: 28px;
  }

  .header-white-64 {
    font-size: 34px;
  }

  .header-white-90 {
    font-size: 48px;
    line-height: 59px;
  }

  .medium-32 {
    font-size: 22px;
  }

  .capital-bold {
    font-size: 21px;
  }

  .other-products {
    margin-bottom: 20px;
  }

  .stroke-numb {
    font-size: 96px;
  }

  .large-numb {
    font-size: 180px;
  }

  .sm-image {
    width: 100%;
    height: auto;
  }

  .maintnode-hero {
    width: 100%;
    height: auto;
  }

  .box-column {
    max-width: 100%;
  }

  .contact-info-block {
    margin-top: 40px;
    max-width: 279px;
  }

  .slide-title {
    font-size: 44px;
    line-height: 51px;
  }

  .slide-subtitle {
    font-size: 20px;
    line-height: 24px;
  }

  .faq-accordion-item .faq-header .btn-header-link {
    font-size: 18px;
    line-height: 21px;
    margin-right: 20px;
  }

  .faq-accordion-item .faq-header .btn-header-link:after {
    font-size: 18px;
    line-height: 21px;
    margin-right: -20px;
  }

  .faq-accordion-item .faq-header .btn-header-link.collapsed::after {
    font-size: 18px;
    line-height: 21px;
  }

  .predictive-box {
    align-content: space-between;
    padding: 60px 30px;
  }

  .maintenance-categories {
    padding: 30px;
  }

  .other-products {
    padding: 30px;
  }

  .em-col {
    padding-right: 115px !important;
  }

  .em-icon {
    width: 100%;
  }
}

.work-t {
  font-size: 23px;
  font-weight: 900;
  line-height: 28px;
}

.work-t {
  font-size: 23px;
  line-height: 28px;
}

.work-th {
  width: 48%;
  margin: 10px 1%;
  border: 1px solid $primary !important;
  border-radius: 5px;
  float: left;
}

.work-thl {
  background-position: center !important;
  background-size: cover !important;
  height: 430px;
  background-repeat: no-repeat !important;
}

.work-thw {
  background-position: center !important;
  background-size: cover !important;
  height: 233px;
  background-repeat: no-repeat !important;
}

/*containers and paddings*/
@media only screen and (max-width:1040px) {

  .large-row {
    padding-top: 240px;
  }

  .sm-pad-row {
    padding-top: 66px;
    padding-bottom: 66px;
  }

  .m1 {
    margin-top: 220px;
  }

  .xl-pad-row {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

@media only screen and (max-width:767px) {
  .m1 {
    margin-top: 180px;
  }

  .xl-pad-row {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .work-th {
    width: 98%;
    margin: 10px 1%;
    border: 1px solid $primary !important;
    border-radius: 5px;
  }

  .work-thl {
    height: 270px;
    border-radius: 5px;
  }
}

@media only screen and (min-width:767px) {
  .div-t-l {
    position: fixed;
  }

  .div-t-r {
    margin-left: 50%;
    padding-bottom: 340px;
  }
}

@media only screen and (max-width:940px) {
  .info-card {
    margin-bottom: 20px;
    padding: 30px;
    text-align: center;
  }

  .info-card-icon {
    margin: 71px auto;
  }
}