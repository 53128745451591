// Borders

.border-top-dashed {
    border-top: dashed !important;
}
.border-bottom-dashed {
    border-bottom: dashed !important;
}
.border-end-dashed {
    border-right: dashed !important;
}
.border-start-dashed {
    border-left: dashed !important;
}
