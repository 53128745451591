// 
// user.scss
// Use this to write your custom SCSS
//

.pattern-generic {
    background-image: url(/assets/images/generic/arpedon-abstract-blue-background-1.jpg);
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;


    &--1 {
        background-image: url(/assets/images/generic/arpedon-abstract-blue-background-1.jpg);
    }

    &--2 {
        background-image: url(/assets/images/generic/arpedon-abstract-blue-background-2.jpg);
    }

    &--3 {
        background-image: url(/assets/images/generic/arpedon-abstract-blue-background-3.jpg);
    }
}

.numeric-display {
    font: normal normal bold 112px/147px RobotoVariable;
    letter-spacing: 0px;
    color: #F3F4F5;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: $secondary;
    opacity: 1;
}

.post-h img {
    width: 100%;
}