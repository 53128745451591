@at-root {
  :root {
    --swiper-theme-color: #{$primary};
  }
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: transparent;
  opacity: 0.3;
  border: 2px solid $primary;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
  border-color: $primary;
}
